const base = '/social'

export const routes = {
  social: `${base}`,
  profile: '/', // Will redirect to PageNotFound (404) if user data does not exist
  bookList: `/book-list`,
  review: '/review',
  quote: '/quote',
  invite: '/invite',
  inviteWelcome: '/invite-welcome',
  post: '/post',
  link: '/link',
  dragonQuiz: '/dragon-quiz',
  chatbot: '/scout',
  chatbotPreview: '/chat-with-scout',
  fanart: '/fanart',
  stats: '/profile/stats',
}
