import { matchPath } from 'react-router-dom'

import checkIcon from './assets/icons/standard/check-icon.svg'

import FacebookIcon from './assets/icons/social/FacebookIcon'
import InstagramIcon from './assets/icons/social/InstagramIcon'
import LinkedinIcon from './assets/icons/social/LinkedinIcon'
import TwitterIcon from './assets/icons/social/TwitterIcon'
import YouTubeIcon from './assets/icons/social/YouTubeIcon'

import appStoreBadge from './assets/images/graphics/badges/app-store-badge.webp'
import appStoreBadgeOutlineGreen from './assets/images/graphics/badges/app-store-badge-outline-green.webp'
import appStoreBadgeLight from './assets/images/graphics/badges/app-store-badge-light.webp'
import appStoreBadgeDark from './assets/images/graphics/badges/app-store-badge-dark.webp'
import appStoreBadgeUltraDark from './assets/images/graphics/badges/app-store-badge-ultra-dark.webp'
import googlePlayBadge from './assets/images/graphics/badges/google-play-badge.webp'
import googlePlayBadgeOutlineGreen from './assets/images/graphics/badges/google-play-badge-outline-green.webp'
import googlePlayBadgeLight from './assets/images/graphics/badges/google-play-badge-light.webp'
import googlePlayBadgeDark from './assets/images/graphics/badges/google-play-badge-dark.webp'
import googlePlayBadgeUltraDark from './assets/images/graphics/badges/google-play-badge-ultra-dark.webp'

import errorEmptyBGPattern from './assets/images/graphics/empty_states/error/background-pattern.svg'
import errorEmptyImage from './assets/images/graphics/empty_states/error/main-image.svg'
import eventsEmptyBGPattern from './assets/images/graphics/empty_states/events/background-pattern.svg'
import eventsEmptyImage from './assets/images/graphics/empty_states/events/main-image.svg'
import ordersEmptyBGPattern from './assets/images/graphics/empty_states/orders/background-pattern.svg'
import ordersEmptyImage from './assets/images/graphics/empty_states/orders/main-image.svg'
import purchasedEmptyBGPattern from './assets/images/graphics/empty_states/purchased/background-pattern.svg'
import purchasedEmptyImage from './assets/images/graphics/empty_states/purchased/main-image.svg'
import wishlistEmptyBGPattern from './assets/images/graphics/empty_states/wishlist/background-pattern.svg'
import wishlistEmptyImage from './assets/images/graphics/empty_states/wishlist/main-image.svg'
import socialEmptyBGPattern from './assets/images/graphics/empty_states/social/background-pattern.svg'
import socialEmptyImage from './assets/images/graphics/empty_states/social/main-image.svg'

import emailIcon from './assets/images/support/email.svg'
import faqIcon from './assets/images/support/faq.svg'

import { routes as marketingRoutes } from './app/marketing/routes'
import { routes as communityRoutes } from './app/community/routes'
import { routes as storeRoutes } from './app/store/routes'

// GENERAL

export const FABLE_TAGLINE = 'Stories for everyone'
export const RSVP_CTA_TEXT = 'RSVP'
export const APP_FONTS = [
  'Heldane Display',
  'Inter',
  'Des Montilles',
  'Palmer Lake Print',
]

// AUTH

export const AUTH_METHODS = {
  apple: 'apple',
  google: 'google',
  microsoft: 'microsoft',
  password: 'password',
  token: 'token',
}

export const CAREER_CLUB_JOIN_KEY = 'careerClubJoinKey'

export const NO_USER_TEXT = 'There is no current user'
export const SET_PASSWORD_HEADER_TEXT = 'Set new password'
export const RESET_PASSWORD_HEADER_TEXT = 'Enter your email to reset password'
export const SIGNIN_BUTTON_TEXT = 'Login with email'
export const SIGNIN_HEADER_TEXT = 'Please log in'
export const SIGNUP_BUTTON_TEXT = 'Sign up with Email'
export const EMPTY_EMAIL_TEXT = 'Please enter your email address'
export const USER_NOT_FOUND = 'There is no account with this email'
export const WRONG_PASSWORD = 'Password is incorrect'
export const EMAIL_IN_USE =
  'There is already an account associated with this email'

// LINKS

export const APP_LINK = 'https://links.fable.co/'
export const DOWNLOAD_APP_LINK =
  'https://links.fable.co/universal-web-download-cta'
export const BOOK_APP_LINK = 'https://universal.fable.co/app/read'

// CATEGORIES

export const CATEGORIES = {
  author: 'author',
  book: 'book',
  folio: 'folio',
  blog: 'blog',
  event: 'event',
  community: 'community',
  bookList: 'book-list',
  genres: 'genres',
  profile: 'profile',
}

// PAGE TITLES

export const TITLES = {
  marketing: {
    about: 'About',
    careers: 'Careers',
    community: 'Community',
    company: 'Company',
    contact: 'Contact',
    main: 'Home',
    press: 'Press',
    privacy: 'Privacy Policy',
    bookClubs: 'Book Clubs',
    terms: 'Terms of Service',
  },
  community: {
    main: 'Community',
    blog: 'Blog',
    events: 'Events',
  },
  store: {
    accountSettings: 'Account Settings',
    yourProfile: 'Your Profile',
    yourClubs: 'Your Clubs',
    profile: 'Profile',
    bookshelf: 'Your Bookshelf',
    explore: 'Explore Books',
    folios: 'Folios',
    genres: 'Genres',
    orders: 'Orders',
    invite: 'Invite friends',
    premium: 'Fable Premium',
    purchased: 'Your Books',
    store: 'Bookstore',
    support: 'Help & Support',
    wishlist: 'Wishlist',
  },
}

// LINKS

export const EMAIL_LINK =
  'mailto:support@fable.co?subject=Contacting you about Fable'
export const EMAIL_REPORT_USER_LINK = (reporterId = '', reportedId = '') =>
  `mailto:support@fable.co?subject=Report a user&body=%0A%0A%0A%0A----------------------%0AReport User or Content%0AUser ID: ${reportedId}%0AReported by User ID: ${reporterId}`
export const CAREERS_LINK =
  'https://jobs.gusto.com/boards/fable-98b12b54-02b4-4af4-bb6d-a4ac2f9932d5'
export const FAQ_LINK = 'https://help.fable.co/'
export const START_A_CLUB_LINK = `${communityRoutes.blog}/how-to-start-a-book-club`

// NOTIFICATION MESSAGES

export const NOTIFICATION_MESSAGES = {
  addedToWishlist: {
    icon: checkIcon,
    message: '<p>Added to your <b>Want to Read</b> list</p>',
  },
  removedFromWishlist: {
    icon: checkIcon,
    message: '<p>Removed from your <b>Want to Read</b> list</p>',
  },
  copiedToClipboard: {
    icon: checkIcon,
    message: '<p>Copied to <b>Clipboard</b></p>',
  },
  infoUpdated: {
    icon: checkIcon,
    message: '<p>Your changes are saved</p>',
  },
  newsletterSubscribed: {
    icon: checkIcon,
    message: '<p>Subscribed</p>',
  },
  bookSaved: {
    icon: checkIcon,
    message: '<p><b>Book saved</b></br>Find it in your bookshelf</p>',
  },
  addedBookToList: {
    icon: checkIcon,
    message: '<p><b>Book added</b> to your list</p>',
  },
  removedBookFromList: {
    icon: checkIcon,
    message: '<p><b>Book removed</b> from your list</p>',
  },
  editedBookListBulk: {
    icon: checkIcon,
    message: '<p><b>Saved changes</b> to your lists</p>',
  },
  editedBookList: {
    icon: checkIcon,
    message: '<p><b>Saved changes</b> to your list</p>',
  },
  deletedBookList: {
    icon: checkIcon,
    message: '<p>Your list has been <b>deleted</b></p>',
  },
  createdBookList: {
    icon: checkIcon,
    message: '<p>Your list has been <b>saved</b></p>',
  },
  clubJoined: {
    icon: checkIcon,
    message: '<p><b>Joined club</b></p>',
  },
}

// NAV ITEMS

export const STORE_NAV_ITEMS = [
  {
    title: 'Explore Books',
    path: storeRoutes.explore,
  },
  {
    title: TITLES.store.folios,
    path: storeRoutes.folios,
  },
  {
    title: TITLES.store.genres,
    path: storeRoutes.genres,
  },
  {
    title: TITLES.store.bookshelf,
    path: storeRoutes.bookshelf,
    isPrivate: true,
  },
]

export const ACCOUNT_NAV_ITEMS = [
  {
    title: TITLES.store.yourProfile,
    path: storeRoutes.profile,
  },
  {
    title: TITLES.store.yourClubs,
    path: storeRoutes.yourClubs,
  },
  {
    title: TITLES.store.accountSettings,
    path: storeRoutes.accountSettings,
  },
  {
    title: TITLES.store.orders,
    path: storeRoutes.accountOrders,
  },
  {
    title: TITLES.store.invite,
    path: storeRoutes.inviteReferral,
  },
]

export const ABOUT_NAV_ITEMS = [
  {
    title: TITLES.marketing.company,
    path: marketingRoutes.company,
  },
  {
    title: TITLES.marketing.press,
    path: marketingRoutes.press,
  },
  {
    title: TITLES.marketing.careers,
    path: CAREERS_LINK,
  },
]

export const COMMUNITY_NAV_ITEMS = [
  {
    title: TITLES.community.blog,
    path: communityRoutes.blog,
  },
  {
    title: TITLES.community.events,
    path: communityRoutes.events,
  },
]

// APP PROVIDER LINKS

export const APP_DOWNLOAD_LINKS = {
  apple:
    'https://apps.apple.com/us/app/fable-stories-for-everyone/id1488170618',
  google: 'https://play.google.com/store/apps/details?id=co.fable.fable',
}

export const APP_DOWNLOAD_DETAILS = [
  {
    title: 'App Store',
    link: APP_DOWNLOAD_LINKS.apple,
    image: {
      light: appStoreBadgeLight,
      outlineGreen: appStoreBadgeOutlineGreen,
      regular: appStoreBadge,
      dark: appStoreBadgeDark,
      ultraDark: appStoreBadgeUltraDark,
    },
    isVector: false,
    isExternal: true,
    newTab: true,
  },
  {
    title: 'Google Play',
    link: APP_DOWNLOAD_LINKS.google,
    image: {
      light: googlePlayBadgeLight,
      outlineGreen: googlePlayBadgeOutlineGreen,
      regular: googlePlayBadge,
      dark: googlePlayBadgeDark,
      ultraDark: googlePlayBadgeUltraDark,
    },
    isVector: false,
    isExternal: true,
    newTab: true,
  },
]

// SUPPORT

export const SUPPORT_CARDS = [
  {
    icon: faqIcon,
    title: 'FAQs',
    description:
      'Find help articles and answers to frequently asked questions.',
    link: FAQ_LINK,
  },
  {
    icon: emailIcon,
    title: 'Email',
    description: 'Contact us by email.',
    link: EMAIL_LINK,
  },
]

// FOOTER

export const COPYRIGHT_TEXT = `©${new Date().getFullYear()} Fable Group Inc.`
export const LEGAL_LINKS = [
  {
    title: 'Help & Support',
    link: marketingRoutes.support,
    newTab: false,
    isExternal: false,
  },
  {
    title: 'Privacy',
    link: marketingRoutes.privacy,
    newTab: false,
    isExternal: false,
  },
  {
    title: 'Copyright Policy',
    link: marketingRoutes.copyrightPolicy,
    newTab: false,
    isExternal: false,
  },
  {
    title: 'Terms of Service',
    link: marketingRoutes.terms,
    newTab: false,
    isExternal: false,
  },
]

export const FOOTER_LINKS = [
  {
    title: 'Clubs',
    links: [
      {
        title: 'Join a book club',
        link: marketingRoutes.home,
        newTab: false,
        isExternal: false,
      },
      {
        title: 'Start a book club',
        link: START_A_CLUB_LINK,
        newTab: true,
        isExternal: true,
      },
      {
        title: 'Premium book clubs',
        link: marketingRoutes.premiumClubs,
        newTab: false,
        isExternal: false,
      },
    ],
  },
  {
    title: 'Bookstore',
    links: [
      {
        title: 'Find a book',
        link: storeRoutes.explore,
        newTab: false,
        isExternal: false,
      },
      {
        title: 'Folios',
        link: storeRoutes.folios,
        newTab: false,
        isExternal: false,
      },
      {
        title: 'Genres',
        link: storeRoutes.genres,
        newTab: false,
        isExternal: false,
      },
      {
        title: 'Bookshelf',
        link: storeRoutes.bookshelf,
        newTab: false,
        isExternal: false,
      },
    ],
  },
  {
    title: 'About us',
    links: [
      {
        title: 'Company',
        link: marketingRoutes.company,
        newTab: false,
        isExternal: false,
      },
      {
        title: 'Careers',
        link: CAREERS_LINK,
        newTab: true,
        isExternal: true,
      },
      {
        title: 'Community Policy',
        link: marketingRoutes.communityGuidelines,
        newTab: false,
        isExternal: false,
      },
      {
        title: 'Press',
        link: marketingRoutes.press,
        newTab: false,
        isExternal: false,
      },
    ],
  },
  {
    title: 'Learn more',
    links: [
      {
        title: 'For Business',
        link: marketingRoutes.b2b,
        newTab: false,
        isExternal: false,
      },
      {
        title: 'For Education',
        link: marketingRoutes.education,
        newTab: false,
        isExternal: false,
      },
      {
        title: 'Blog',
        link: communityRoutes.blog,
        newTab: false,
        isExternal: false,
      },
      {
        title: 'Events',
        link: communityRoutes.events,
        newTab: false,
        isExternal: false,
      },
    ],
  },
]

export const SOCIAL_MEDIA_LINKS = [
  {
    title: 'Twitter',
    link: 'https://twitter.com/getfable',
    image: <TwitterIcon />,
    isVector: true,
    isExternal: true,
    newTab: true,
  },
  {
    title: 'Instagram',
    link: 'https://instagram.com/fable',
    image: <InstagramIcon />,
    isVector: true,
    isExternal: true,
    newTab: true,
  },
  {
    title: 'Linkedin',
    link: 'https://www.linkedin.com/company/fablegroup/',
    image: <LinkedinIcon />,
    isVector: true,
    isExternal: true,
    newTab: true,
  },
  {
    title: 'Facebook',
    link: 'https://www.facebook.com/getfable',
    image: <FacebookIcon />,
    isVector: true,
    isExternal: true,
    newTab: true,
  },
  {
    title: 'YouTube',
    link: 'https://www.youtube.com/channel/UCqI3TsDOJOa9wgz1XJ8sc-A',
    image: <YouTubeIcon />,
    isVector: true,
    isExternal: true,
    newTab: true,
  },
]

// EMPTY STATES
export const EMPTY_STATE_TYPES = {
  wishlist: {
    bgImage: wishlistEmptyBGPattern,
    mainImage: wishlistEmptyImage,
    title: 'No Book added yet',
    description: 'The books you like will appear here',
    accentColor: '#73D5FF',
  },
  purchased: {
    bgImage: purchasedEmptyBGPattern,
    mainImage: purchasedEmptyImage,
    title: 'No Book added yet',
    description: 'The books you buy will appear here',
    accentColor: '#064C37',
  },
  orders: {
    bgImage: ordersEmptyBGPattern,
    mainImage: ordersEmptyImage,
    title: 'No orders registered yet',
    description: 'Your purchase history and receipts will appear here',
    accentColor: '#064C37',
  },
  events: {
    bgImage: eventsEmptyBGPattern,
    mainImage: eventsEmptyImage,
    title: 'More events coming soon',
    description:
      'We are working to organize the best events for this community. Stay tuned.',
    accentColor: '#064C37',
  },
  error: {
    bgImage: errorEmptyBGPattern,
    mainImage: errorEmptyImage,
    title: 'Something went wrong',
    description: 'Please, try again later',
    accentColor: '#CB3F3F',
  },
  social: {
    bgImage: socialEmptyBGPattern,
    mainImage: socialEmptyImage,
    description: 'This user account and content is private.',
    accentColor: '#CB3F3F',
  },
}

// ERRORS

export const DEFAULT_ERROR_MESSAGE =
  "Sorry, it's not you it's us, please try again."

// STYLES

export const BREAKPOINTS = {
  mobileXS: '321px',
  mobileS: '376px',
  mobile: '548px',
  tablet: '768px',
  desktop: '1152px',
  desktopL: '1440px',
  desktopXL: '1680px',
}

export const STYLE_ATTRIBUTES = {
  headerHeight: '60px',
  subheaderHeight: '52px',
}

export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || 'development'

// GEO IP COUNTRY
export const GEO_IP_COUNTRY_SET = 'geoIpCountrySet'

// LOCAL STORAGE KEYS
const keyPrefix = 'fwa' // (Fable Web App)

export const lsKeys = {
  postAuthRedirect: 'postAuthRedirect',
  cartId: `${keyPrefix}CartId`,
  promoTest: `${keyPrefix}:promoTest`,
}

// PROMO CODES
export const promoCodes = {
  readMore: 'READMORE',
}

// TRACKING AND COOKIE BLACKLIST
export const TRACKING_BLACKLIST = (pathname) =>
  [matchPath('/iosprivacy', pathname), matchPath('/iosterms', pathname)].some(
    (isTrue) => !!isTrue
  )
